import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Layout from '../../../../../components/layout'
import { removeNumberPrefix } from '../../../../../utilities'
import MobileCarousel from '../../../../../components/case-studies/MobileCarousel'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class Letenky extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter
    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Tvorba eshopu Primadonna Collection'}
          description={"Prípadová štúdia"}
          description2={'Vlastný eshop na mieru pre exkluzívnu taliansku značku so zameraním na ženskú módu.  '}
          text={'Klientka nás oslovila s potrebou prekopať jej pôvodný eshop a dostať ho na vyššiu úroveň a do modernejšieho vzhľadu, aby sa zvýšili jeho tržby. To sa nakoniec aj podarilo. Pozrite sa, ako sme sa s tým popasovali.'}
          img={data.landingImage}
          pageMode={false}
          noShadow={true}
          what_we_done={[
            { text: 'UX & web design' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (php + javascript)' }
          ]}/>

        <AboutProject
          title="Úspešný eshop nevznikne sám od seba"
          text={'Toto všetko obnáša tvorba eshopov, ktoré klientom úspešne a dlhodobo zarábajú.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Klientka nás oslovila s požiadavkou vytvoriť nový <a class="blue-link" href="/eshop-na-mieru">eshop</a>. Najskôr sme si s ňou prešli všetky požiadavky, aby sme <b>pochopili jej očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila. Pochopili sme čo potrebuje a pridali sme množstvo našich odporúčaní o ktorých vieme, že zaručene fungujú a predávajú.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Úloha bola jasná. Vytvoriť moderný a intuitívny eshop, ktorý bude vizuálne imponovať ženskému cieľovému publiku a v ktorom si vždy rady nakúpia exkluzívne oblečenie. Musí byť už na prvý klik zrejmé, o aký eshop ide, a zároveň mať najlepšiu úroveň <b>funkcií, rýchlosti a SEO</b>. Jednoducho, to najlepšie zo sveta programovania a <a class="blue-link" href="/webdizajn">webového dizajnu</a>.',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Pri projektoch sa často navrhuje aj <a class="blue-link" href="/branding">korporátna identita</a>. Po schválení sme sa pustili do <a class="blue-link" href="/ux-ui-dizajn">wireframov</a> (rozloženia stránok) a finálneho webdizajnu. Pravidelne sme klientovi prezentovali výstupy a <b>diskutovali o nich</b>. Projekt sme finálne zverili do rúk našich programátorov.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením web optimalizujeme pre prehliadače, responzívnosť a SEO, pre čo najlepšie pozície na Googli. Odovzdaním webu spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Sledujeme správanie návštevníkov cez Hotjar a Google Analytics a na základe získaných dát klientom radíme ako <b>zvýšiť ich tržby</b>.',
            }
          ]}
        />

        <PagesShowcase
          fixedTitleWidth={true}
          title={'Webový dizajn na mieru'}
          description={'Profesionálny eshop sa bez neho nezaobíde.'}
          text={'Design eshopu je svieži, jednoduchý a prehľadný, čo je predpokladom k pozitívnemu užívateľskému zážitku (UX). Neodmysliteľnou súčasťou webu sú vysoko kvalitné optimalizované produktové fotografie, ktoré ihneď upútajú návštevníka a pôsobia dôveryhodne.'}
          projectDetail={'/nase-prace/webstranky/eshopy/primadonna-collection'}//TODO
          pages={data.pages.nodes.map(node => ({ image: node, name: removeNumberPrefix(node.name) }))}
        />

        {/* All images */}
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="primadonna" />
          </div>
        </Reveal>
         
        <MobileCarousel
          description='Väčšina návštevníkov webstránky prichádza cez mobily. Myslíme na to.'
          items={data.mobileGallery.nodes}
          smallDescription="Vytvoriť responzívny web do posledného detailu je kľúčové, pretože určuje, či bude <a href='/ux-ui-dizajn' class='red-link'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na smartfónoch (mobile first), tabletoch a ďalších rozlíšeniach. <br/><br/>Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastný eshop a chce, aby mu prinášal čo najväčší zisk."
        />

       {/*  <ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        /> */}

       {/*  <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.primadonnacollection.sk" target='_blank' className='link-secondary'>www.primadonnacollection.sk</a>
        </div> */}

        
        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Roomfactory'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/eshopy/roomfactory/'}
        />
      </Layout>
    )
  }
}
export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/primadonna/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/piramaddona/visual.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pages: allFile(filter: { relativePath: {regex: "/(case-studies/piramaddona/pages/)/"} }, sort: {fields:name}) {
        nodes {
            name
            childImageSharp {
                fluid( maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
    mobileGallery: allFile(filter: { relativePath: {regex: "/(case-studies/piramaddona/mobileGallery/)/"} }, sort: {fields:name}) {
        nodes {
            childImageSharp {
                fluid( maxWidth: 320) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
}
`

export default Letenky
